body {
  font-family: var(--primary-font);
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  z-index: 2;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  background-color:white; 
}

/* for media folder */

/* 
.gallery-assets .album-view{
    border: 0 !important;
}
.gallery-assets .album-title {
    background-color: #FFDA69;
    color: black;
    border-bottom: 5px #C8A53A solid;
    position: relative;
}
.gallery-assets .album-title::before,
.gallery-assets .album-title::after {
  content: "";
  position: absolute;
  width: 45%;
  height: 20px;
  background: #FFDA69;
  border-radius: 0 15px 0 0;
  top: -20px;
  right: 0;
}
.gallery-assets .album-title::after {
  top: 0;
  width: 50px;
  height: 20px;
  border-radius: 0 0px 0 0;
  background-color: #FFDA69;
  right: 117px;
  top: -20px;
  transform: skewX(-50deg);
  border-radius: 6px 0 0 0;
} */
.ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: calc(100% - 40px);
}

.ReactCrop__child-wrapper>.before-crop-image {
  max-width: initial;
  width: auto;
}

.crop-now .ReactCrop__child-wrapper {
  overflow-x: auto;
  /* padding-bottom: 20px; */
}

.album-set-icon a {
  height: 246px;

}



.grid-view-nav {
  gap: 0 24px;
}

.grid-view-nav li {
  width: calc(100%/4 - 20px);
}

.grid-view-nav .left-nav,
.grid-view-nav .nav-action,
.grid-view-nav .image-nav,
.grid-view-nav .card-title {
  width: 100%;
}

.grid-view-nav .card-title {
  padding: 0;
}

/* Website Switcher */
.switch-back a {
  background-color: var(--secondary-color);
  padding: 14px 0;
}

.switch-website .form-check-input:checked {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.toggle-website {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--bs-black);
  border: rgba(13, 134, 134, 0.15) solid;
}



.modal-backdrop {
  z-index: 1050;
  /* Adjust as needed */
}

.modal {
  z-index: 1051;
  /* Adjust as needed, higher than .modal-backdrop */
}

.menu-action-btns {
  position: absolute;
  right: 50px;
  top: 11px;
  z-index: 99;
}

.showpassword {
  top: 50px;
  right: 15px;
}

.login-wrapper .showpassword {
  top: 15px;
}

/* .imagecropmodel .modal-dialog {
  max-width: calc(100% - 10px);
} */
.imagecropmodel .modal-body{
  height: calc(100vh - 220px);
}
.guest-dropdown{
  border: 1px var(--border-color) solid !important;
  border-radius: 0;
 .guests-input_options {
      max-width: 175px;
      margin: 20px auto 0;
      padding: 0;
      label{
          text-transform: capitalize;
          text-align: left;
          width: 100%;
          font-size: 14px;
          color: var(--gondola);
          margin: 0 0 4px;
      }
      .guest-card{
          border: 1px var(--border-color) solid;
          font-size: 13px;
          padding: 4px 15px;
      }
      .guests-input__ctrl{
          border: 0;
          padding: 5px;
          font-size: 13px;
      }
  }
  .guest-dropdown-item{
      padding: 0;
  }
} 
.guests-input__options {
  position: absolute;
  width: 100%;
  background-color: var(--bs-white);
  -webkit-box-shadow: rgba(72, 72, 72, 0.2) 0px 15px 20px;
  box-shadow: rgba(72, 72, 72, 0.2) 0px 15px 20px;
  border-radius: 2px;
  overflow: hidden;
  height: 0;
  opacity: 0;
  -webkit-transition: all .1s linear;
  transition: all .1s linear;
  &.open {
      opacity: 1;
      height: 146px
  }
  > div {
      padding: 10px 0;
      text-align: center;
      &:first-child{
          padding-top: 35px
      }
      &:last-child{
          padding-bottom: 35px
      }
  }
}
.guests-input__ctrl {
  display: inline-block;
  border: 1px solid var(--border-color);
  font-size: 20px;
  color: var(--gondola);
  padding: 3px 8px;
  line-height: 20px;
  border-radius: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  &.disabled {
      color: var(--border-color);
      border-color: var(--border-color);
      cursor: default
  }
}
.guests-input__value {
  display: inline-block;
  padding: 0 10px;
  width: 100px;
  cursor: default;
  span {
      display: inline-block;
      padding-right: 5px
  }
}


.amenity-upload-img {
  width: 44px;
  height: 58px;
  margin-right: 5px;
}

.amenity-upload-img img {
  width: 100%;
}

.specification-dropdown .booking-form-input:not(:last-child) {
  margin-bottom: 16px;
}

.error {
  border: 1px solid red;
}